<template>
  <div class="search-box">
    <div class="container">
      <img src="@/static/images/icon_search.png" alt />
      <form action="#" @submit.prevent="search">
        <input ref="input" v-model="keywords" class="text-14 default-font-color" type="search" :placeholder="placeholder" @input="input" @focus="focus" @blur="blur" />
      </form>
    </div>
    <div v-if="isEditing" class="cancel-text" @click="cancel">
      取消
    </div>
    <!-- <img v-else class="filter_img" src="@/static/images/icon_filter.png" alt @click="filter" /> -->
  </div>
</template>

<script>
export default {
  name: "searchBox",
  props: {
    flag: Boolean,
    msg: Array,
    placeholder: {
      type: String,
      default: "请输入关键字",
    },
    onCancel: Function,
    onTextChanged: Function,
    onFilterClicked: Function,
    onSearchClicked: Function,
  },
  data: function () {
    return {
      keywords: "",
      isEditing: false,
      enterKey: "",
    };
  },
  methods: {
    input: function () {
      this.onTextChanged(this.keywords);
    },
    focus: function () {
      this.isEditing = true;
    },
    blur: function () { },
    cancel: function () {
      this.keywords = "";
      this.isEditing = false;
      this.onCancel();
    },
    filter: function () {
      this.onFilterClicked();
    },
    search: function () {
      this.$refs.input.blur();
      this.isEditing = false;
      this.onSearchClicked(this.keywords);
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #999999;
}
::-webkit-search-cancel-button {
  display: none;
}
</style>